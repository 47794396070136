<template>
<div class="single-data">
  <div class="single-data-header">
    <el-date-picker
        @change="selectTimeChange"
        style="width: 254px;"
        v-model="dateScopeValue"
        type="daterange"
        range-separator="~"
        start-placeholder="开始日期"
        :clearable="false"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        end-placeholder="结束日期">
    </el-date-picker>
    <span>按开播时间筛选</span>
  </div>
  <el-table :data="tiktokLiveData" style="width: 100%; margin-top: 10px;flex: 1" height="1%" size="medium" class="attention-table"
            :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#333333', fontSize:'16px'}"
            :cell-style="{fontSize: '16px',color: '#333333',height: '160px',}">
    <el-table-column align="left" label="房间id" width="360">
      <template slot-scope="scope">
        <div class="line-live-info">
          <div class="live-info-img">
            <img :src="scope.row.room_cover_image" alt="">
          </div>
          <div class="live-info-content">
            <span class="text-overflow">{{scope.row.room_name}}</span>
            <span>直播时间:{{scope.row.open_time}}</span>
            <span>直播时长:{{scope.row.duration}}</span>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" width="290"  prop="watch_pcu" label="观看人数"></el-table-column>
    <el-table-column align="center" width="290" prop="watch_ucnt" label="在线人数峰值"></el-table-column>
    <el-table-column align="center" width="280"  prop="comment_ucnt" label="评论人数"></el-table-column>
    <!-- <el-table-column align="center" prop="consume_ucnt" label="付费人数"></el-table-column>
    <el-table-column align="center" prop="score" label="收获音浪"></el-table-column>
    <el-table-column align="center" prop="income" label="收益"></el-table-column>
    <el-table-column align="center" label="更多">
      <template slot-scope="scope">
        <span style="color: #584EEE;cursor: pointer;" @click="lookAudience(scope.row)">查看</span>
      </template>
    </el-table-column> -->
  </el-table>
  <el-dialog
      title="查看更多数据"
      custom-class="like-look-dialog"
      :visible.sync="dialogVisible"
      @opened="echartsSetting"
      width="600px">
      <div style="width: 100%;display: flex;justify-content: center;align-items: center;flex-direction: column;">
          <div class="look-dialog-name">观众分布</div>
          <div id="LiveAudienceDistribution" style="width: 560px;height: 460px;"></div>
          <div v-if="echartsData.length === 0">暂无数据</div>
      </div>

  </el-dialog>
</div>
</template>

<script>
import {liveRoomData, liveRoomDetail} from "@/utils/apis";
import { mapGetters } from "vuex"
import dayjs from "dayjs"
import * as echarts from "echarts"

export default {
  name: "LiveSingleData",
  data(){
    return {
      dateScopeValue:[],
      pickerOptions:{
        // disabledDate(time){
        //   console.log(time.getTime())
        //   return time.getTime() > Date.now();
        // }
      },
      tiktokLiveData:[],
      liveDataPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0
      },
      dialogVisible: false,
      AudienceDistributionNoPrompt:null,
        echartsIns: null,
      accountID:null,
      platform:null,
      generalSourceList:[],
        echartsData: [],
    }
  },
  mounted() {
    this.AudienceDistributionNoPrompt = document.createElement("div");
    this.roomInit()
  },
    computed: {
        ...mapGetters([
            'accountValue'
        ])
    },
  methods:{
    deleDateScopeValue(){
      this.dateScopeValue = [];
      this.tiktokLiveData = [];
      this.accountID = '';
      this.platform = '';
      this.liveDataPages = {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0
      }
      this.pickerOptions = {
        disabledDate(){

        }
      }

    },
      roomInit() {
        const begin_date = dayjs().subtract(7, 'day').format("YYYY-MM-DD")
        const end_date = dayjs().subtract(1, 'day').format("YYYY-MM-DD")
          this.dateScopeValue = [begin_date, end_date]
          this.getRoomList()
      },
      async getRoomList() {
        const param = {
            account_id: this.accountValue,
            begin_date: 0,
            end_date: 0,
        }
        param.begin_date = dayjs(this.dateScopeValue[0]).unix()
          param.end_date = dayjs(this.dateScopeValue[1]).unix()

        const res = await liveRoomData(param)
          if (res.code === 200) {
              this.tiktokLiveData = res.data
          } else {
              this.$message.warning(res.msg)
          }
      },
      async roomMoreDetail(room_id) {
            const param = {
                room_id: room_id,
                account_id: this.accountValue
            }
            const res = await liveRoomDetail(param)
            if (res.code === 200) {
                this.echartsData = []
                res.data.general_source_list.forEach((item) => {
                    const val = {
                        value: Number(item.count),
                        name: item.source
                    }
                    this.echartsData.push(val)
                })
                this.dialogVisible = true
            } else {
                this.$message.warning(res.msg)
            }
      },
      echartsSetting() {
            if (this.echartsData.length === 0) {
                return
            }
          this.echartsIns = echarts.init(document.getElementById("LiveAudienceDistribution"))
          let option = {
              tooltip: {
                  trigger: 'item'
              },
              legend: {
                  top: '5%',
                  left: 'center',
                  // doesn't perfectly work with our tricks, disable it
                  selectedMode: false
              },
              series: [
                  {
                      type: 'pie',
                      radius: ['40%', '70%'],
                      // adjust the start angle
                      label: {
                          show: true,
                          formatter(param) {
                              // correct the percentage
                              return param.name + ' (' + param.percent + '%)';
                          }
                      },
                      data: [...this.echartsData]
                  }
              ]
          };
          this.$nextTick(() => {
              this.echartsIns.setOption(option)
          })


      },
    selectTimeChange(val){
        this.getRoomList()
    },
// 点击查看
    lookAudience(row){
        this.roomMoreDetail(row.room_id)
    },
  },
    openLiveDialog() {}
}
</script>

<style scoped lang="scss">
.single-data{
  height: calc(100vh - 260px);
  //height: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 20px 20px;
  .single-data-header{
    display: flex;
    align-items: center;
    span{
      font-size: 14px;
      color: #333;
      margin-left: 12px;
    }
  }
}
::v-deep .like-look-dialog{
  .el-dialog__header{
    margin:0 20px;
    padding: 20px 0;
    color: #333;
    font-size: 18px;
    border-bottom: #DDDDDD 1px solid;
  }
  .el-dialog__body{
    padding: 20px 20px 60px;
    .look-dialog-name{
      font-size: 18px;
      color: #333;
      text-align: center;
    }
  }
}
.line-live-info{
  width: 100%;
  display: flex;
  .live-info-img{
    width: 105px;
    height: 130px;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  .live-info-content{
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    flex: 1;
    width: 1%;
    span{
      color: #999999;
      font-size: 12px;
      &:nth-child(1){
        color: #333;
        font-size: 16px;
      }
      &:nth-child(2){
        margin: 18px 0;
      }
    }
  }
}
</style>
