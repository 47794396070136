import { render, staticRenderFns } from "./LiveSingleData.vue?vue&type=template&id=866d6342&scoped=true"
import script from "./LiveSingleData.vue?vue&type=script&lang=js"
export * from "./LiveSingleData.vue?vue&type=script&lang=js"
import style0 from "./LiveSingleData.vue?vue&type=style&index=0&id=866d6342&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "866d6342",
  null
  
)

export default component.exports