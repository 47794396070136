<template>
  <div class="data-situation">
    <div class="data-situation-header">
      <div class="situation-top-item">
        <span>直播时长</span>
        <span>{{getStudentLiveData.live_duration}}</span>
      </div>
      <div class="situation-top-item">
        <span>观看人数</span>
        <span>{{getStudentLiveData.watch_cnt}}</span>
      </div>
      <div class="situation-top-item">
        <span>人均观看时长（分）</span>
        <span>{{getStudentLiveData.watch_avg_time}}</span>
      </div>
      <div class="situation-top-item">
        <span>新增粉丝数</span>
        <span>{{getStudentLiveData.new_fans_ucnt}}</span>
      </div>
      <span class="lately-title">近30天数据</span>
    </div>
    <div class="data-situation-basics">
      <div class="situation-basics-header">
        <div class="basics-title">
          <div class="title-line"></div>
          <span>基础数据</span>
        </div>
        <div class="basics-select-time">
          <div class="basics-time-item" @click="toggleBasics(1)" :class="{'time-item-active' : basicsRecently === 1}">7天</div>
          <div class="basics-time-item" @click="toggleBasics(2)" :class="{'time-item-active' : basicsRecently === 2}">30天</div>
        </div>
      </div>
      <div class="situation-basics-select">
        <el-select style="width: 170px;" v-model="basicsValue" placeholder="请选择" @change="selectBasicsChange">
          <el-option
              v-for="item in basicsList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div id="basicsData" style="width: 100%;height: 442px;position: relative" v-show="basicStatus"></div>
        <div style="width: 100%;" id="basic-no-data" v-show="!basicStatus"></div>
    </div>
    <div class="data-situation-view">
      <div class="situation-basics-header">
        <div class="basics-title">
          <div class="title-line"></div>
          <span>观看数据</span>
        </div>
        <div class="basics-select-time">
          <div class="basics-time-item" @click="toggleView(1)" :class="{'time-item-active' : viewRecently === 1}">7天</div>
          <div class="basics-time-item" @click="toggleView(2)" :class="{'time-item-active' : viewRecently === 2}">30天</div>
        </div>
      </div>
      <div class="situation-basics-select">
        <el-select style="width: 170px;" v-model="viewValue" placeholder="请选择" @change="selectViewChange">
          <el-option
              v-for="item in viewList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div id="viewData" style="width: 100%;height: 442px;position: relative" v-show="viewStatus"></div>
        <div v-show="!viewStatus" style="width: 100%;" id="view-no-data"></div>
    </div>
    <div class="data-situation-interaction">
      <div class="situation-basics-header">
        <div class="basics-title">
          <div class="title-line"></div>
          <span>互动数据</span>
        </div>
        <div class="basics-select-time">
          <div class="basics-time-item" @click="toggleInteraction(1)" :class="{'time-item-active' : interactionRecently === 1}">7天</div>
          <div class="basics-time-item" @click="toggleInteraction(2)" :class="{'time-item-active' : interactionRecently === 2}">30天</div>
        </div>
      </div>
      <div class="situation-basics-select">
        <el-select style="width: 170px;" v-model="interactionValue" placeholder="请选择" @change="selectInteractionChange">
          <el-option
              v-for="item in interactionList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div id="interactionData" style="width: 100%;height: 442px;position: relative" v-show="interactionStatus"></div>
        <div v-show="!interactionStatus" id="interaction-no-data" style="width: 100%;"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {liveGeneralData, liveTrendsData, liveSummarize} from "@/utils/apis";
import * as echarts from 'echarts'
export default {
  name: "LiveDataSituation",
  data() {
    return {
      basicsRecently: 1,
      basicsValue:1,
      basicsList:[
        {
          value:1,
          label:'开播次数'
        },
        {
          value:2,
          label:'开播时长（分钟）'
        }
      ],
      // 观看数据
      viewRecently:1,
      viewValue:1,
      viewList:[
        {
          value:1,
          label:'观看人次'
        },
        {
          value:2,
          label:'观看人数'
        },
        {
          value:3,
          label:'观看人数峰值'
        },
        {
          value:4,
          label:'人均观看时长（分钟）'
        },
      ],
      // 互动数据
      interactionRecently:1,
      interactionValue:2,
      interactionList:[
        // {
        //   value:1,
        //   label:'送礼人数'
        // },
        {
          value:2,
          label:'评论人数'
        },
        {
          value:3,
          label:'新增粉丝'
        },
      ],
        getStudentLiveData: {
            live_duration: 0,
            watch_cnt: 0,
            watch_avg_time: 0,
            new_fans_ucnt: 0
        },
        basicDataEcharts: null,
        basicDataList: [],
        basicStatus: true,
        viewDataEcharts: null,
        viewDataList: [],
        viewStatus: true,
        interactionEcharts: null,
        interactionDataList: [],
        interactionStatus: true,
    }
  },
    props: {
      accountId: [Number, String],
    },
  mounted() {
        this.init()
  },
    computed: {
      ...mapGetters([
          'accountValue'
      ])
    },
  methods: {
      init(){
        this.basicDataEcharts = echarts.init(document.getElementById("basicsData"))
        this.viewDataEcharts = echarts.init(document.getElementById("viewData"))
        this.interactionEcharts = echarts.init(document.getElementById("interactionData"))
        window.onresize = () => {
          // 基础数据
          this.basicDataEcharts.resize()
          // // 观看数据
            this.viewDataEcharts.resize()
          // // 互动数据
            this.interactionEcharts.resize()
        }
      },
      selectBasicsChange() {
          this.liveTrendsData()
      },
      selectViewChange() {
          this.liveViewsTrends()
      },
      selectInteractionChange() {
          this.liveInteractions()
      },
      toggleBasics(val) {
          this.basicsRecently = val
          this.liveTrendsData()
      },
      toggleView(val) {
          this.viewRecently = val
          this.liveViewsTrends()
      },
      toggleInteraction(val) {
          this.interactionRecently = val
          this.liveInteractions()
      },
      async generalDataFun() {
          const params = {
              account_id: this.accountValue
          }
          const res = await liveSummarize(params)
          if (res.code === 200) {
              this.getStudentLiveData.live_duration = res.data.yes_live_duration
              this.getStudentLiveData.watch_cnt = res.data.yes_watch_cnt
              this.getStudentLiveData.watch_avg_time = res.data.yes_watch_avg_time
              this.getStudentLiveData.new_fans_ucnt = res.data.yes_new_fans_ucnt
          } else {
              this.$message.warning(res.msg)
          }

      },
      async liveTrendsData() {
            const params = {
                day_window: this.basicsRecently === 1 ? 7 : 30,
                metrics_type: this.basicsValue === 1 ? 502 : 501,
                account_id: this.accountValue
            }
            const res = await liveTrendsData(params)
            if (res.code === 200 ) {
                if (res.data === null) {
                    this.nullDataHTML(document.getElementById("basic-no-data"), params.day_window)
                    this.basicStatus = false
                    return
                }
                this.basicDataList = res.data
                let xData = []
                let yData = []
                res.data.map((item) => {
                    xData.push(item.date)
                    if (this.basicsValue === 2) {
                        yData.push(item.value.toFixed(2))
                    } else {
                        yData.push(item.value)
                    }
                })
                const desc = this.basicsValue === 1 ? "开播次数" : "开播时长"
                const unit = this.basicsValue === 1 ? "" : "min"
                this.basicStatus = true
                this.basicDataEchartsInit(this.basicDataEcharts, xData, yData, this.basicDataList, desc, unit)

            } else {
                this.$message.warning(res.msg)
            }
      },
      async liveViewsTrends() {
          let title = ""
          let unit = ""
          const params = {
              day_window: this.viewRecently === 1 ? 7 : 30,
              metrics_type: 201,
              account_id: this.accountValue
          }
          switch (this.viewValue) {
              case 1:
                  params.metrics_type = 201
                  title = "观看人次"
                  break;
              case 2:
                  params.metrics_type = 202
                  title = "观看人数"
                  break;
              case 3:
                  params.metrics_type = 203
                  title = "观看人数峰值"
                  break;
              case 4:
                  params.metrics_type = 205
                  title = "人均观看时长"
                  break;
          }
          const res = await liveTrendsData(params)
          if (res.code === 200) {
              if (res.data === null) {
                  this.nullDataHTML(document.getElementById("view-no-data"), params.day_window)
                  this.viewStatus = false
                  return
              }
              let yData = [];
              let xData = [];
              if (params.metrics_type === 205) {
                  unit = "min"
              }
              res.data.forEach((item) => {
                  xData.push(item.date)
                  if (params.metrics_type === 205) {
                      yData.push(item.value.toFixed(2))
                  } else {
                      yData.push(item.value)
                  }
                  this.viewDataList = res.data
              })
              this.basicDataEchartsInit(this.viewDataEcharts, xData, yData,this.viewDataList, title, unit)
          } else {
              this.$message.warning(res.msg)
          }
      },
      async liveInteractions() {
          let title = ""
          const params = {
              day_window: this.interactionRecently === 1 ? 7 : 30,
              metrics_type: 301,
              account_id: this.accountValue
          }
          switch (this.interactionValue) {
              case 1:
                  title = "送礼人数"
                  params.metrics_type = 301
                  break;
              case 2:
                  title = "评论人数"
                  params.metrics_type = 303
                  break;
              case 3:
                  title = "新增粉丝"
                  params.metrics_type = 401
                  break;
          }
          const res = await liveTrendsData(params)
          if (res.code === 200 ) {
              if (res.data === null) {
                  this.nullDataHTML(document.getElementById("interaction-no-data"), params.day_window)
                  this.interactionStatus = false
                  return
              }
              let xData = []
              let yData = []
              this.interactionDataList = res.data
              res.data.forEach((item) => {
                  xData.push(item.date)
                  yData.push(item.value)
              })
              this.interactionStatus = true
              this.basicDataEchartsInit(this.interactionEcharts, xData, yData, this.interactionDataList, title, "")
          } else {
              this.$message.warning(res.msg)
          }
      },
      nullDataHTML(element, day_window) {
          const text = `<div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">
            <div class="svg" style="display: flex;justify-content: center;align-items: center;flex-direction: column;">
                <div class="no-data" style="width: 200px;height: 200px;"></div>
                <p style="color: #252632cc;">近${day_window}日没有开播，暂无数据，去开直播吧</p>
            </div>

</div>`
          element.innerHTML = text
      },
      basicDataEchartsInit(echartsIns, xData=[], yData=[], dataList = [], title = "", unit="") {
          let option = {
              title: {
                  text: this.basicsRecently === 1 ? "近7天趋势" : "近30天趋势",
                  textStyle: {
                      fontSize: 14,
                  },
                  left: 10,
              },
              xAxis: {
                  type: "category",
                  boundaryGap: false,
                  data: [...xData],
              },
              yAxis: {
                  type: "value"
              },
              tooltip: {
                  trigger: "axis",
                  formatter: (params) => {
                      let str = ''
                      params.forEach((item) => {
                          const value = item.value + unit
                          str = this.toolTipFormatter(item.axisValue, item.marker, value, dataList, item.dataIndex, title)
                      })
                      return str
                  },
              },
              series: [
                  {
                      data: [...yData],
                      type: "line",
                      smooth: true,
                      areaStyle: {}
                  }
              ]
          };
          echartsIns.setOption(option)
      },
      toolTipFormatter(axisValue, marker, value, dataList=[], dataIndex, desc="") {
          const percent = dataList[dataIndex].lastValue
          let percent_value
          if (percent > 0) {
              percent_value = percent * 100
              percent_value = `${percent_value}%<i class="el-icon-top" style="color: #F93353;"></i>`
          } else {
              percent_value = percent * -100
              percent_value = `${percent_value}%<i class="el-icon-bottom" style="color: #00B47F;"></i>`
          }
          return `
              <div style="width: 160px;">
                <div style="color: #51515C;">${axisValue}</div>
                <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
                    <div>${marker} <span style="color: #292A37;font-weight: bold;">${desc}</span></div>
                    <div style="font-weight: bold; color: #292A37;">${value}</div>
                </div>
                <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
                    <div style="color: #94949B;">较昨日</div>
                    <div>${percent_value}</div>
                </div>
              </div>
          `;
      }
  }
}
</script>

<style scoped lang="scss">
.data-situation {
  width: 1260px;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  .data-situation-header {
    display: flex;
    justify-content: space-between;
    padding: 34px 132px 82px 52px;
    position: relative;
    .lately-title{
      position: absolute;
      left: 20px;
      top: -10px;
      font-size: 14px;
      font-weight: bold;
    }
    .situation-top-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        line-height: 1;

        &:nth-child(1) {
          font-size: 14px;
          color: #666666;
          font-weight: 400;
        }

        &:nth-child(2) {
          color: #584EEE;
          font-size: 24px;
          font-weight: bold;
          margin-top: 20px;
        }
      }
    }
  }

  .data-situation-basics ,.data-situation-view, .data-situation-interaction{
    padding: 0 50px 0 20px;
    &.data-situation-interaction{
      padding-bottom: 40px;
    }
    .situation-basics-header {
      display: flex;
      line-height: 1;
      justify-content: space-between;

      .basics-title {
        display: flex;
        align-items: center;

        .title-line {
          width: 5px;
          height: 20px;
          background: #584EEE;
          border-radius: 3px;
          margin-right: 10px;
        }

        span {
          font-size: 16px;
          color: #333;
        }
      }

      .basics-select-time {
        display: flex;

        .basics-time-item {
          background: #F1F0FE;
          font-size: 12px;
          color: #666666;
          line-height: 30px;
          text-align: center;
          width: 50px;
          height: 30px;
          cursor: pointer;

          &:nth-child(1) {
            border-radius: 5px 0px 0px 5px;
          }

          &:nth-child(2) {
            border-radius: 0px 5px 5px 0px;
          }
        }

        .time-item-active {
          background: #564CED;
          color: #fff;
        }
      }
    }
    .situation-basics-select{
      margin-top: 22px;
      padding-left: 14px;
        margin-bottom: 10px;
    }
  }
}
</style>
